export const defaultSettings = {

  borderradius:10,
  height:420,
 

  width1: 500,
  background1: "#9900EF",
  headingsize1:"44",
  subheadingsize1:"25",
  fontcolor1:"#ffff",

  width2: 500,
  background2: "#00D084",
  headingsize2:"44",
  subheadingsize2:"25",
  fontcolor2:"#ffff"
};

